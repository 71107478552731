<template>
    <el-dialog class='grade-dialog' :title='`论文详情`' :visible.sync='show' :close-on-click-modal='false' width='1000px'
        :before-close='cancel'>
        <el-form ref='form' :model='formData' :rules='rules' label-width='120px'>
            <el-divider content-position="left">
                <h3>个人信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='16'>
                    <el-form-item label="姓名：" prop="userId">
                        {{ formData.realName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='部门：' prop='deptName'>
                        {{ formData.deptName }}
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='工号：' prop='teacherNo'>
                        {{ formData.teacherNo }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider content-position="left">
                <h3>论文信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='学年：' prop='gradeName'>
                        {{ formData.gradeName }}
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='学期：' prop='term'>
                        第{{ formData.term }}学期
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='论文题目：' prop='paperTitle'>
                        {{ formData.paperTitle }}
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='本人角色：' prop='authorRole'>
                        {{ formData.authorRole }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='论文收录情况：' prop='paperInclusionStatus'>
                        {{ formData.paperInclusionStatus }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider content-position="left">
                <h3>发表信息:</h3>
            </el-divider>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='发表刊物名称：' prop='publishTitle'>
                        {{ formData.publishTitle }}
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='发表刊物级别：' prop='journalRank'>
                        {{ formData.journalRank }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='发表时间：' prop='publishTime'>
                        {{ formData.publishTime }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='发表卷号：' prop='publishVolume'>
                        {{ formData.publishVolume }}
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='发表期号：' prop='publishIssue'>
                        {{ formData.publishIssue }}
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label='起始页码：' prop='startPage'>
                        {{ formData.startPage }}
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='结束页码：' prop='endPage'>
                        {{ formData.endPage }}
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import userAutocomplete from '@/vue/mixins/user-autocomplete'

import { getThesisByIdApi } from '@/api/achievement/thesis-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog, userAutocomplete],
    data() {
        return {
            id: '',
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptId: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                paperTitle: '',
                authorRole: '',
                paperInclusionStatus: '',
                publishTitle: '',
                journalRank: '',
                publishTime: '',
                publishIssue: '',
                publishVolume: '',
                startPage: '',
                endPage: ''

            },
            gradeList: {},
            termList: {}
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.id) {
                this.getDataById(this.id, getThesisByIdApi)
            } else {
            }
        })
    },
    methods: {

    }
}
</script>
