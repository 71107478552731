<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="姓名"
                :tool-list="['keyword', 'gradeName', 'term']">

                <el-form-item label="部门" v-permission="['achievement:thesis:all']">
                    <el-select v-model="queryInfo.deptId" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  departmentList " :key="index" :label="item.deptName"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="刊物级别">
                    <el-select v-model="queryInfo.journalRank" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  journalRankList " :key="index" :label="item.type"
                            :value="item.type" />
                    </el-select>
                </el-form-item>
            </view-search-form>
            <div class="header-button mb-1">
                <el-tag class="successNum">一般公开刊物:{{ count.generalPublication }};省级:{{ count.provinceLevel }};国家级:{{
                    count.
                        countryLevel }};核心期刊:{{ count.corePublication }};</el-tag>
                <el-button @click="exportExcel()" type="success" size="small">
                    论文成果导出
                </el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column type='index' label='ID' width='50' />
            <el-table-column prop="realName" label="姓名" width="70"></el-table-column>
            <el-table-column prop="deptName" label="部门" min-width="120"></el-table-column>
            <el-table-column prop="teacherNo" label="工号"></el-table-column>
            <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term}学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="paperTitle" label="论文题目"></el-table-column>
            <el-table-column prop="publishTitle" label="发表刊物名称" min-width="120"></el-table-column>
            <el-table-column prop="publishTime" label="发表时间" min-width="100"></el-table-column>
            <el-table-column prop="publishIssue" label="发表期号"></el-table-column>
            <el-table-column prop="publishVolume" label="发表卷号"></el-table-column>
            <el-table-column prop="startPage" label="起始页码"></el-table-column>
            <el-table-column prop="endPage" label="结束页码"></el-table-column>
            <el-table-column prop="authorRole" label="本人角色"></el-table-column>
            <el-table-column prop="paperInclusionStatus" label="论文收录情况" min-width="120"></el-table-column>
            <el-table-column prop="journalRank" label="发表刊物级别" min-width="120"></el-table-column>
            <el-table-column label="操作" min-width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        查看详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-Thesis ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
    </table-view>
</template>

<script>
import { pageThesisApi, delThesisApi, countThesisApi } from '@/api/achievement/thesis-api.js'
import { journalRankList } from './components/options'
import dialogThesis from './components/DialogThesis.vue'
import tableView from '@/vue/mixins/table-view'
import store from '@/store/index'

export default {
    mixins: [tableView],
    components: { dialogThesis },
    data() {
        return {
            queryInfo: {
                startDate: null,
                endDate: null,
                deptId: null,
                gradeName: null,
                term: null,
                journalRank: null
            },
            journalRankList,
            departmentList: '',
            formData: {
                id: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                term: '',
                paperTitle: '',
                patentName: '',
                publishTitle: '',
                publishTime: '',
                publishVolume: '',
                startPage: '',
                endPage: '',
                authorRole: '',
                paperInclusionStatus: '',
                journalRank: ''
            },
            count: {}
        }
    },
    mounted() {
        this.renderTable()
    },
    methods: {
        delThesisApi,
        countThesisApi() {
            countThesisApi(this.queryInfo).then((res) => {
                this.count = res.data
            })
        },
        async renderTable(pageNum) {
            this.getAll()
            this.countThesisApi()
            this.getDepartment()
            await this.getTableData(pageThesisApi, pageNum)
        },
        // 查看全部的权限
        getAll() {
            const _permission = store.getters['login/getPermission']

            if (!(_permission.includes('achievement:thesis:all'))) {
                this.queryInfo.deptId = this.$store.state.login.userInfo.deptId
            }
        },
        // 获取部门
        getDepartment() {
            this.$http.getDepartmentListQuery({}).then((res) => {
                this.departmentList = res.data
            })
        },
        // 导出
        exportExcel() {
            this.$http.exportExcelHttp.exportThesis(this.queryInfo)
        }

    }

}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
