// 发表刊物级别列表
export const journalRankList = [
    { type: '一般公开刊物' },
    { type: '省级' },
    { type: '国家级' },
    { type: '核心期刊' }
]
// 本人角色列表
export const authorRoleList = [
    { type: '独撰' },
    { type: '第一主编' },
    { type: '第二主编' },
    { type: '参编' }
]
